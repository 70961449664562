import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildPatientId } from '../../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone, formatDateWithFormat, handleExtension, handleReference } from '../../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import { Provenance } from '../../../../../types/FHIRTypes/Provenance'
import useFhirResourceById from '../../../../../hooks/admin/useFhirResourceById'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../../types/FieldConfig'
import MetaDataFooter from '../../MetaDataFooter'


interface Props {
  detailId: string;
  immunizationData: any;
  patientData: any;
  isFetching: boolean;
}

const ImmunizationDetail: FC<Props> = ({ detailId, immunizationData, patientData, isFetching }: Props) => {
  const config: FieldConfig = {
    patient: { label: 'Patient', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    statusReason: { label: 'Reason Vaccine Not Performed', visibility: 'conditional' },
    vaccineCode: { label: 'Vaccine Code', visibility: 'always' },
    occurence: { label: 'Date Administered', visibility: 'always' },
    encounter: { label: 'Encounter', visibility: 'conditional' },
    performer: { label: 'Performer', visibility: 'conditional' },
    primarySource: { label: 'Primary Source', visibility: 'always' },
    location: { label: 'Location', visibility: 'conditional' },
    route: { label: 'Route', visibility: 'conditional' },
    doseQuantity: { label: 'Dose Quantity', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
  }
  const [resource, setResource] = useState<any>(null)
  const history = useHistory()


  useEffect(() => {
    if (!immunizationData) return

    const foundImmunization = immunizationData.entry.find((immunization: any) => immunization.resource.id === detailId)
    if (foundImmunization) {
      setResource(foundImmunization.resource)
    } else {
      history.push('/404')
    }
  }, [immunizationData])

  return (
    <section>
      {
        isFetching ? (
          <tr>
            <td colSpan={6} rowSpan={15} className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Immunizations...</span>
            </td>
          </tr>
        ) : resource ? (
          <>
            <dl className='dataContainer'>
              <Row>
                <ShowField field='patient' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.patient.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.patient)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.status}</dd>
                  </Col>
                </ShowField>

                <ShowField field='statusReason' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.statusReason.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.statusReason)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='vaccineCode' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.vaccineCode.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.vaccineCode)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='occurence' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.occurence.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource?.occurrenceDateTime}</dd>
                  </Col>
                </ShowField>


                <ShowField field='encounter' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.encounter)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='performer' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.performer.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource?.performer?.map((performer: any, index: any) => (
                      <tr key={`performer-${index}`}>
                        <td>{`Function: ${handleCodeableConcept(performer.function)}`}</td>
                        <td>{`Actor: ${handleReference(performer.function)}`}</td>
                      </tr>
                    ))}

                    </dd>
                  </Col>
                </ShowField>


                <ShowField field='primarySource' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.primarySource.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{!resource.primarySource?.extension ? resource?.primarySource : handleExtension(resource?.primarySource?.extension)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='location' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.location.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.location)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='route' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.route.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.route)}</dd>
                  </Col>
                </ShowField>

                <ShowField field='doseQuantity' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.doseQuantity.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource?.doseQuantity?.value} {resource?.doseQuantity?.unit}</dd>
                  </Col>
                </ShowField>

                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.text}</dd>
                  </Col>
                </ShowField>
                <Col sm={12}>
                  <MetaDataFooter
                    resourceType={resource?.resourceType}
                    resourceId={resource?.id}
                    resourceMeta={resource?.meta}
                    resourceLanguage={resource?.language}
                    resourceImplicitRules={resource?.implicitRules}
                  />
                </Col>

                <Col sm={12}>
                  <ProvenanceDetail resourceName='Immunization' resourceId={detailId} />
                </Col>
              </Row>
            </dl>
          </>
        ) :
          <tr>
            <td colSpan={6} rowSpan={15} className='text-center'>
              No Immunizations Details found for this member.
            </td>
          </tr>
      }
    </section>
  )
}

export default ImmunizationDetail