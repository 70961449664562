import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { formatDateInTimeZone, formatDateWithFormat, handleExtension, handleReference } from '../../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import MetaDataFooter from '../../MetaDataFooter'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'


interface Props {
    conditionDetailId: any;
    conditionData: any;
    isFetching: boolean;
}


const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    clinicalStatus: { label: 'Clinical Status', visibility: 'always' },
    verificationStatus: { label: 'Verification Status', visibility: 'always' },
    category: { label: 'Category', visibility: 'always' },
    code: { label: 'Code', visibility: 'always' },
    onset: { label: 'Onset Date Time', visibility: 'conditional' },
    abatement: { label: 'Abatement ', visibility: 'conditional' },
    severity: { label: 'Severity', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Update', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'always' },
    Identifier: { label: 'Resource Identifier', visibility: 'always' },
}

const ConditionDetail: FC<Props> = ({ conditionDetailId, conditionData, isFetching }: Props) => {
    const [resource, setResource] = useState<any>(null)

    const history = useHistory()

    useEffect(() => {
        if (!conditionData) return

        const foundCondition = conditionData.entry.find((condition: any) => condition.resource.id === conditionDetailId)
        if (foundCondition) {
            setResource(foundCondition.resource)
        } else {
            history.push('/404')
        }
    }, [conditionData])

    return (
        isFetching ? (
            <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                </td>
            </tr>
        ) : resource ? (
            <>
                <dl className='dataContainer'>
                    <Row>
                        <ShowField field='subject' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.subject.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleReference(resource.subject)}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='clinicalStatus' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.clinicalStatus.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(resource.clinicalStatus)}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='verificationStatus' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.verificationStatus.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(resource.verificationStatus)}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='category' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.category.label}</dt>
                            </Col>
                            <Col sm={9}>
                                {resource.category.map((category: any, index: any) => (
                                    <tr key={`category-${index}`}>
                                        <td>{!category.extension ? handleCodeableConcept(category) : handleExtension(category.extension)}</td>
                                    </tr>
                                ))}
                            </Col>
                        </ShowField>

                        <ShowField field='code' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.code.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(resource.code)}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='onset' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.onset.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{resource.onsetDateTime}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='abatement' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.abatement.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{resource.abatement}</dd>
                            </Col>
                        </ShowField>

                        <ShowField field='severity' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.severity.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{handleCodeableConcept(resource.abatement)}</dd>
                            </Col>
                        </ShowField>

                        <Col sm={12}>
                            <MetaDataFooter
                                resourceType={resource?.resourceType}
                                resourceId={resource?.id}
                                resourceMeta={resource?.meta}
                                resourceLanguage={resource?.language}
                                resourceImplicitRules={resource?.implicitRules}
                            />
                        </Col>

                        <Col sm={12}>
                            <ProvenanceDetail resourceName='Condition' resourceId={conditionDetailId} />
                        </Col>
                    </Row>
                </dl>
            </>

        ) :
            <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                    No Immunizations Details found for this member.
                </td>
            </tr>
    )
}


export default ConditionDetail