import React, { FC, useEffect } from 'react'
import { CarePlanBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { capitalizeFirstLetter, formatDateWithFormat, handleCodeableConcept, handleReference, handleExtension } from '../../../../../utils/helpers'
import CarePlanActivity from '../CarePlanActivity'
import CareTeamDetail from '../../CareTeams/CareTeamDetail'
import { buildPeriod } from '../../../../../utils/helpers'
import ProvenanceDetail from '../../Provenance'
import { getDataAbsentReason } from '../../../../../utils/fhirUtils/getDataAbsentReason'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'  
import Narrative from '../../../DisplayComponents/Narrative'

interface Props {
  carePlanDetailId: string
  carePlanData: CarePlanBundle
  isFetching: boolean
}

const config: FieldConfig = {
  text: { label: 'Care Plan Summary', visibility: 'always' },
  subject: { label: 'Subject', visibility: 'always' },
  category: { label: 'Category', visibility: 'always' },
  intent: { label: 'Intent', visibility: 'always' },
  status: { label: 'Status', visibility: 'always' },
  title: { label: 'Title', visibility: 'conditional' },
  description: { label: 'Description', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  period: { label: 'Period', visibility: 'conditional' },
  created: { label: 'Created', visibility: 'conditional' },
  author: { label: 'Author', visibility: 'conditional' },
  contributor: { label: 'Contributor', visibility: 'conditional' },
  careTeam: { label: 'Care Team', visibility: 'conditional' },
  addresses: { label: 'Addresses', visibility: 'conditional' },
  supportingInfo: { label: 'Supporting Info', visibility: 'conditional' },
  goal: { label: 'Goal', visibility: 'conditional' },
  activity: { label: 'Activity', visibility: 'conditional' },
  basedOn: { label: 'Based On', visibility: 'never' },
  replaces: { label: 'Replaces', visibility: 'never' },
  partOf: { label: 'Part Of', visibility: 'never' },
  note: { label: 'Note', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const CarePlanDetail: FC<Props> = ({ carePlanDetailId, carePlanData, isFetching }) => {
  const carePlanEntry = carePlanData?.entry?.find((carePlan: any) => carePlan.resource.id === carePlanDetailId)
  const resource = carePlanEntry?.resource

  const includedCareTeams = resource?.careTeam?.map((ref: any) => {
    return carePlanData?.entry?.find((entry: any) => entry.resource.id === ref.reference.split('/')[1])
  }).filter((entry: any) => entry !== undefined) ?? []

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as='span'
              animation='border'
              role='status'
              aria-hidden='true'
            />
            <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          resource ? (
            <div>
            
              <Row>
                {/* <ShowField field='text' config={config} resource={resource}>
                  <Col sm={12} className='my-2'>
                    <Card className='mx-2'>
                      <Card.Header className='d-flex justify-content-between'>
                        <dt>{config.text.label}</dt>
                        {resource.text?.status && (
                          <dd>Status: {capitalizeFirstLetter(resource.text.status)}</dd>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <dd dangerouslySetInnerHTML={{ __html: resource.text?.div ?? '' }} />
                      </Card.Body>
                    </Card>
                  </Col>
                </ShowField> */}
                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={3}>
                      <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={9}>
                      {resource.text && (<dd><Narrative text={resource.text} /></dd>)}
                      {resource.text?.status && (<dd>Status: {capitalizeFirstLetter(resource.text.status)}</dd>)}
                  </Col>
                </ShowField>
                <ShowField field='subject' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subject.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.subject)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='category' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.category.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.category)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='intent' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.intent.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.intent) || getDataAbsentReason(resource, 'intent') || ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.status) || getDataAbsentReason(resource, 'status') || ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='title' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.title.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.title ?? ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='description' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.description.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.description ?? ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='encounter' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.encounter)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='period' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.period.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{buildPeriod(resource.period)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='created' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.created.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.created ?? ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='author' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.author.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.author)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='contributor' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.contributor.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.contributor)}</dd>
                  </Col>
                  </ShowField>
                  <ShowField field='careTeam' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.careTeam.label}</dt>
                    </Col>
                    <Col sm={11} className='my-2'>
                      {includedCareTeams.map((careTeam: any) => (
                        <CareTeamDetail
                          key={careTeam.resource.id}
                          careTeamData={{
                            id: 'careTeamBundleId',
                            resourceType: 'Bundle',
                            total: 1,
                            type: 'searchset',
                            link: [],
                            entry: [careTeam],
                            meta: {}
                          }}
                          careTeamDetailId={careTeam.resource.id}
                          isFetching={false}
                        />
                      ))}
                    </Col>
                  </ShowField>
                <ShowField field='addresses' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.addresses.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.addresses)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='supportingInfo' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.supportingInfo.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.supportingInfo)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='goal' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.goal.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.goal)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='activity' config={config} resource={resource}>
                  <Col sm={12}>
                    <CarePlanActivity patientCarePlan={resource} />
                  </Col>
                </ShowField>
                <ShowField field='basedOn' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.basedOn.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.basedOn)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='replaces' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.replaces.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.replaces)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='partOf' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.partOf.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.partOf)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='note' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.note.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.note?.map((note) => note.text).join(', ') ?? ''}</dd>
                  </Col>
                </ShowField>
              </Row>
            
            <div className='footer'>
            <hr />

              <h6>FHIR Resource Metadata</h6>
              <Row>
                <ShowField field='resourceType' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.resourceType.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.resourceType}</dd>
                  </Col>
                </ShowField>
                <ShowField field='id' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.id.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.id}</dd>
                  </Col>
                </ShowField>
                <ShowField field='meta' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.meta.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {resource.meta?.lastUpdated
                        ? formatDateWithFormat(
                            resource.meta.lastUpdated,
                            'MM/dd/yyyy HH:mm:ss'
                          )
                        : ''}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='profile' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.profile.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.meta?.profile?.join(', ')}</dd>
                  </Col>
                </ShowField>
                <ShowField field='language' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.language.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.language}</dd>
                  </Col>
                </ShowField>
                <ShowField field='implicitRules' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.implicitRules.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.implicitRules}</dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.identifier?.map((identifier: any) => identifier.value).join(', ')}</dd>
                  </Col>
                </ShowField>
              </Row>
              </div>
              <Row>
                <Col sm={12}>
                  <ProvenanceDetail resourceName='CarePlan' resourceId={carePlanDetailId} />
                </Col>
              </Row>
            
          </div>
          ) : (
            <div className='text-center'>
              No Care Plan found for this member.
            </div>
          )
        }
      </dl>
    </>
  )
}

export default CarePlanDetail