import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CarePlan, Activity } from '../../../../../types/FHIRTypes/CarePlan'
import { handleCodeableConcept, handleReference } from '../../../../../utils/helpers'
import CarePlanActivityDetail from '../CarePlanActivityDetail'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../../types/FieldConfig'

interface Props {
  patientCarePlan: CarePlan;
}

const config: FieldConfig = {
  outcome: { label: 'Outcome', visibility: 'never' },
  outcomeReference: { label: 'Outcome Reference', visibility: 'never' },
  progress: { label: 'Progress', visibility: 'never' },
  reference: { label: 'Reference', visibility: 'never' },
  detail: { label: 'Detail', visibility: 'conditional' },
}

const CarePlanActivity: FC<Props> = ({ patientCarePlan }) => {
  const activities: Activity[] = patientCarePlan.activity || []

  return (
    <>
      {activities.map((activity, index) => (
        <div key={`care-plan-activity-${index}`}>
        {/* <Card key={`care-plan-activity-${index}`} className='mt-3 w-100'> */}
          {/* <Card.Header>
            <div>
              <p>Activity {index + 1}</p>
            </div>
          </Card.Header> */}
          {/* <Card.Body> */}
            <Row>
              <ShowField field='outcome' config={config} resource={activity}>
                <Col sm={3}>
                  <dt>{config.outcome.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{handleCodeableConcept(activity.outcomeCodeableConcept)}</dd>
                </Col>
              </ShowField>
              <ShowField field='outcomeReference' config={config} resource={activity}>
                <Col sm={3}>
                  <dt>{config.outcomeReference.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{handleReference(activity.outcomeReference)}</dd>
                </Col>
              </ShowField>
              <ShowField field='progress' config={config} resource={activity}>
                <Col sm={3}>
                  <dt>{config.progress.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{activity.progress?.map((progress) => progress.text).join(', ')}</dd>
                </Col>
              </ShowField>
              {activity.reference ? (
                <ShowField field='reference' config={config} resource={activity}>
                  <Col sm={3}>
                    <dt>{config.reference.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(activity.reference)}</dd>
                  </Col>
                </ShowField>
              ) : activity.detail ? (
                <ShowField field='detail' config={config} resource={activity}>
                  <Col sm={12}>
                    <CarePlanActivityDetail activity={activity} />
                  </Col>
                </ShowField>
              ) : null}
            </Row>
          {/* </Card.Body> */}
          {/* </Card> */}
        </div>
      ))}
    </>
  )
}

export default CarePlanActivity