/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CodeableConcept } from '../types/FHIRTypes/CodeableConcept'
import { Coding } from '../types/FHIRTypes/Coding'
import { Extension } from '../types/FHIRTypes/Extension'

export const isCoding = (data: any): data is Coding => {
    if (typeof data !== 'object' || data === null) {
        return false
    }
    if (data.extension && Array.isArray(data.extension)) {
        return false
    }
    const hasValidSystem = data.system === undefined || data.system === null || typeof data.system === 'string'
    const hasValidCode = data.code === undefined || data.code === null || typeof data.code === 'string'
    const hasValidDisplay = data.display === undefined || data.display === null || typeof data.display === 'string' 

    return hasValidSystem && hasValidCode && hasValidDisplay
}

export const isExtension = (data: any): data is Extension => {
    if (typeof data !== 'object' || data === null) {
        return false
    }

    if (typeof data.url !== 'string') {
        return false
    }

    const optionalStringProps = [
        'valueBase64Binary', 'valueCanonical', 'valueCode', 'valueDate', 'valueDateTime',
        'valueId', 'valueInstant', 'valueMarkdown', 'valueOid', 'valueString', 'valueTime',
        'valueUri', 'valueUrl', 'valueUuid', 'valueSignature'
    ]

    const optionalNumberProps = [
        'valueDecimal', 'valueInteger', 'valuePositiveInt', 'valueUnsignedInt', 'valueAge',
        'valueCount', 'valueDistance', 'valueDuration'
    ]

    const optionalBooleanProps = ['valueBoolean']

    const optionalObjectProps = [
        'valueAddress', 'valueAnnotation', 'valueAttachment', 'valueCodeableConcept', 'valueCoding',
        'valueContactPoint', 'valueHumanName', 'valueIdentifier', 'valueMoney', 'valuePeriod',
        'valueQuantity', 'valueRange', 'valueRatio', 'valueReference', 'valueSampledData',
        'valueTiming', 'valueContactDetail', 'valueContributor', 'valueDataRequirement',
        'valueExpression', 'valueDosage', 'valueMeta'
    ]

    const checkOptionalProps = (props: string[], typeCheck: (value: any) => boolean) => {
        return props.every(prop => data[prop] === undefined || typeCheck(data[prop]))
    }

    const isString = (value: any) => typeof value === 'string'
    const isNumber = (value: any) => typeof value === 'number'
    const isBoolean = (value: any) => typeof value === 'boolean'
    const isObject = (value: any) => typeof value === 'object' && value !== null

    return (
        checkOptionalProps(optionalStringProps, isString) &&
        checkOptionalProps(optionalNumberProps, isNumber) &&
        checkOptionalProps(optionalBooleanProps, isBoolean) &&
        checkOptionalProps(optionalObjectProps, isObject)
    )

}

export const isCodeableConcept = (data: any): data is CodeableConcept => {
    if (typeof data !== 'object' || data === null) {
        return false
    }
    if (data.extension && Array.isArray(data.extension)) {
        return false
    }

    const hasValidText = data.text === undefined || data.text === null || typeof data.text === 'string'
    const hasValidCoding = data.coding === undefined || (Array.isArray(data.coding) && data.coding.every(isCoding))

    return hasValidText && hasValidCoding
}