import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { formatDateInTimeZone } from '../../../../../utils/helpers'
import { handleCodeableConcept, handleReference, capitalizeFirstLetter, formatDateWithFormat } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import Narrative from '../../../DisplayComponents/Narrative'
import MetaDataFooter from '../../MetaDataFooter'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { AllergyIntolerance } from '../../../../../types/FHIRTypes/AllergyIntolerance'



interface Props {
    allergyDetailId: any;
    allergyData: any;
    isFetching: boolean;
}
const config: FieldConfig = {
    patient: { label: 'Patient', visibility: 'always' },
    clinicalStatus: { label: 'Clinical Status', visibility: 'always' },
    verificationStatus: { label: 'Verification Status', visibility: 'always' },
    type: { label: 'Type', visibility: 'conditional' },
    category: { label: 'Category', visibility: 'conditional' },
    criticality: { label: 'Criticality', visibility: 'conditional' },
    code: { label: 'Code', visibility: 'always' },
    reaction: { label: 'Adverse Reaction', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },

    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const AllergyDetail: FC<Props> = ({ allergyDetailId, allergyData, isFetching }: Props) => {
    const [allergy, setAllergy] = useState<AllergyIntolerance | null>(null)
    const history = useHistory()

    useEffect(() => {
        if (!allergyData) return

        const foundAllergy = allergyData.entry.find((a: any) => a.resource.id === allergyDetailId)
        if (foundAllergy) {
            setAllergy(foundAllergy.resource)
        } else {
            history.push('/404')
        }
    }, [allergyData, allergyDetailId, history])

    return (
        <>
            {isFetching && (
                <div className='d-flex dataContainer'>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Allergies...</span>
                </div>
            )}
            <dl className='dataContainer'>
                {allergy ? (
                    <div>
                        <Row>
                            <ShowField field='patient' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.patient.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {handleReference(allergy?.patient)}
                                </Col>
                            </ShowField>
                            <ShowField field='clinicalStatus' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.clinicalStatus.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy?.clinicalStatus ? handleCodeableConcept(allergy.clinicalStatus) : ''}
                                </Col>
                            </ShowField>
                            <ShowField field='verificationStatus' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.verificationStatus.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy?.verificationStatus ? handleCodeableConcept(allergy.verificationStatus) : ''}
                                </Col>
                            </ShowField>


                            <ShowField field='type' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.type.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy.type ? capitalizeFirstLetter(allergy.type) : ''}
                                </Col>
                            </ShowField>

                            <ShowField field='category' config={config} resource={allergy}>

                                <Col sm={3}>
                                    <dt>{config.category.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy?.category?.[0]}
                                </Col>

                            </ShowField>
                            <ShowField field='criticality' config={config} resource={allergy}>

                                <Col sm={3}>
                                    <dt>{config.criticality.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy?.criticality}
                                </Col>
                            </ShowField>
                            <ShowField field='code' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.code.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {handleCodeableConcept(allergy?.code)}
                                </Col>
                            </ShowField>
                            {/* <Col sm={3}>
                            <dt>ID</dt>
                        </Col>
                        <Col sm={9}>
                            {allergy?.resource?.id || ''}
                        </Col> */}

                            {/* everything else */}

                            {/* <Col sm={3}>
                        <dt>Allergin</dt>
                    </Col>
                    <Col sm={9}>
                        {allergy?.resource?.coding?.[0]?.display || ''}
                    </Col> */}

                            {/* <Col sm={3}>
                        <dt>Onset</dt>
                    </Col>
                    <Col sm={9}>
                        {allergy?.resource?.Onset || ''}
                    </Col> */}
                        </Row>

                        <Row>
                            <ShowField field='reaction' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.reaction.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {allergy?.reaction?.map((item: any, index: number) => (
                                        <Card key={index} className='mt-3 w-100'>
                                            <Card.Body>

                                                <Row>
                                                    <Col xs={6}>
                                                        Substance:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {handleCodeableConcept(item?.substance)}
                                                    </Col>
                                                    <Col xs={6}>
                                                        Reaction:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {handleCodeableConcept(item?.manifestation?.[0])}
                                                    </Col>
                                                    <Col xs={6}>
                                                        Description:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {item?.description}
                                                    </Col>
                                                    <Col xs={6}>
                                                        Onset:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {item?.onset}
                                                    </Col>
                                                    <Col xs={6}>
                                                        Severity:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {item.severity}
                                                    </Col>
                                                    <Col xs={6}>
                                                        Exposure Route:
                                                    </Col>
                                                    <Col xs={6}>
                                                        {handleCodeableConcept(item?.exposureRoute)}
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Col>
                            </ShowField>

                            <ShowField field='text' config={config} resource={allergy}>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{allergy?.text && <Narrative text={allergy.text} />}</dd>
                                </Col>
                            </ShowField>
                        </Row>
                        <div className='footer'>
                            <hr />
                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy?.resourceType ? capitalizeFirstLetter(allergy.resourceType) : ''}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy.id}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {allergy.meta?.lastUpdated
                                                ? formatDateWithFormat(
                                                    allergy.meta.lastUpdated,
                                                    'MM/dd/yyyy HH:mm:ss'
                                                )
                                                : ''}
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy.meta?.profile?.join(', ')}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy.language}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy.implicitRules}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='identifier' config={config} resource={allergy}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{allergy.identifier?.map((identifier: any) => identifier.value).join(', ')}</dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        </div>
                        <Row>
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='AllergyIntolerance' resourceId={allergyDetailId} />
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div className='text-center'>
                        {' No Allergy Details found for this member.'}
                    </div>
                )}
            </dl>
        </>
    )
}
export default AllergyDetail