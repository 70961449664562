import React, { useEffect } from 'react'
import { ProcedureBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { ProcedureEntry } from '../../../../../types/FHIRTypes/Entry'
import { capitalizeFirstLetter, handleCodeableConcept, handleReference, formatDateWithFormat, formatIdentifier} from '../../../../../utils/helpers'
import { Procedure } from '../../../../../types/FHIRTypes/Procedure'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DisplayComponents/Narrative'



interface ProcedureDetailProps {
    procedureDetailId: string | undefined;
    procedureData: ProcedureBundle;
    isFetching: boolean;
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    code: { label: 'Procedure Code', visibility: 'always' },
    encounter: { label: 'Encounter', visibility: 'conditional' },
    performed: { label: 'When Performed', visibility: 'always' },
    reasonCode: { label: 'Reason Code', visibility: 'conditional' },
    reasonReference: { label: 'Reason Reference', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },

    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}
const ProcedureDetail: React.FC<ProcedureDetailProps> = ({ procedureData, procedureDetailId, isFetching }) => {

    const [procedure, setProcedure] = React.useState<ProcedureEntry | undefined>(undefined)
    const history = useHistory()


    useEffect(() => {
        if (!procedureData) return

        const foundProcedure = procedureData?.entry?.find((procedure) => procedure.resource.id === procedureDetailId)
        if (foundProcedure) {
            setProcedure(foundProcedure)
        } else {
            history.push('/404')
        }
    }, [procedureData])

    const getProcedurePerformedDate = (procedure: Procedure) => {
        if (procedure.performedDateTime) {
            return new Date(procedure.performedDateTime).toLocaleDateString()
        }

        if (procedure.performedPeriod) {
            const startDate = procedure.performedPeriod.start ? new Date(procedure.performedPeriod.start).toLocaleDateString() : ''
            const endDate = procedure.performedPeriod.end ? new Date(procedure.performedPeriod.end).toLocaleDateString() : ''
            return endDate ? `${startDate} - ${endDate}` : startDate
        }

        if (procedure.performedString) {
            return procedure.performedString
        }

        if (procedure.performedAge) {
            return `${procedure.performedAge}`
        }

        if (procedure.performedRange) {
            return `${procedure.performedRange.low.value} - ${procedure.performedRange.high.value}`
        }

        return ''
    }

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                />
                Loading Procedure...
            </div>
        )
    }
    return (
        <>
            <dl className='dataContainer'>
                {procedure?.resource ? (
                    <div>
                        
                            <Row>
                                <ShowField field='subject' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.subject.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{handleReference(procedure?.resource.subject)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='status' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.status.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{capitalizeFirstLetter(procedure?.resource.status)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='code' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.code.label}</dt>
                                    </Col>

                                    <Col sm={9}>
                                        <dd>{procedure?.resource.code ? handleCodeableConcept(procedure?.resource.code) : ''}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='encounter' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.encounter.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{handleReference(procedure?.resource.encounter)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='performed' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.performed.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure ? getProcedurePerformedDate(procedure.resource) : ''}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='reasonCode' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.reasonCode.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {
                                                procedure?.resource.reasonCode?.map((reason) => handleCodeableConcept(reason)).join(', ')
                                            }
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='reasonReference' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.reasonReference.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {
                                                procedure?.resource.reasonReference?.map((reason) => handleReference(reason)).join(', ')
                                            }
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='text' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource?.text && <Narrative text={procedure?.resource.text} />}</dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        
                        <div className='footer'>
                        <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.resourceType ? capitalizeFirstLetter(procedure?.resource.resourceType) : ''}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.id}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {procedure?.resource?.meta?.lastUpdated
                                                ? formatDateWithFormat(
                                                    procedure.resource.meta.lastUpdated,
                                                    'MM/dd/yyyy HH:mm:ss'
                                                )
                                                : ''}
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.meta?.profile?.join(', ')}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.language}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='implicitRules' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.implicitRules}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='identifier' config={config} resource={procedure.resource}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{procedure?.resource.identifier?.map((identifier: any) => formatIdentifier(identifier)).join(', ')}</dd>
                                    </Col>
                                </ShowField>
                                

                            </Row>
                            </div>
                            <Row>
                            <Col sm={12}>
                                    <ProvenanceDetail resourceName='Procedure' resourceId={procedureDetailId} />
                                </Col>
                            </Row>
                        
                    </div>
                ) : (
                    <div className='text-center'>
                        {' No Procedures found for this member.'}
                    </div>
                )}
            </dl>
        </>
    )
}

export default ProcedureDetail