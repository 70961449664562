import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept, handleReference, handleExtension } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import { MedicationRequest } from '../../../../../types/FHIRTypes/MedicationRequest'
import { buildPatientName } from '../../../../../utils/fhirUtils/patient'
import ProvenanceDetail from '../../Provenance'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../../types/FieldConfig'
import Narrative from '../../../DisplayComponents/Narrative'
import MetaDataFooter from '../../MetaDataFooter'

interface MedicationDetailProps {
    medicationDetailId: any;
    medicationData: any;
    isFetching: boolean;
    patientData: any
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    medication: { label: 'Medication', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    statusReason: { label: 'Status Reason', visibility: 'conditional' },
    intent: { label: 'Intent Code', visibility: 'always' },
    category: { label: 'Category', visibility: 'conditional' },
    encounter: { label: 'Encounter', visibility: 'always' },
    authoredOn: { label: 'Date When Written', visibility: 'always' },
    requester: { label: 'Prescriber', visibility: 'always' },
    dosageInstruction: { label: 'Dosage Instruction', visibility: 'always' },
    dispenseRequest: { label: 'Dispense Request', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    //metadata
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}



const MedicationDetail: React.FC<MedicationDetailProps> = ({ medicationDetailId, medicationData, isFetching, patientData }) => {
    const [resource, setResource] = useState<MedicationRequest>({} as MedicationRequest)

    const history = useHistory()
    useEffect(() => {
        if (!medicationData) return

        const foundMedication = medicationData.entry.find((a: any) => a.resource.id === medicationDetailId)
        if (foundMedication) {
            setResource(foundMedication.resource)
        } else {
            history.push('/404')
        }
    }, [medicationData])

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                Loading Medication...
            </div>
        )
    }


    return (
        <>
            {isFetching && (
                <>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true' />
                    Loading Member Medications
                </>
            )}
            <dl className='dataContainer'>
                {resource && (
                    <>
                        <Row>
                            <ShowField field='subject' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.subject.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{handleReference(resource.subject)}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='medication' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.medication.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {resource?.medicationCodeableConcept
                                            ? handleCodeableConcept(resource?.medicationCodeableConcept)
                                            : handleReference(resource?.medicationCodeableReference)}

                                    </dd>
                                </Col>
                            </ShowField>

                            <ShowField field='status' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.status.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource.status}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='statusReason' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.statusReason.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{handleCodeableConcept(resource.statusReason)}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='intent' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.intent.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource.intent}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='category' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.category.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource?.category?.[0]?.coding?.[0] ? handleCodeableConcept({ coding: [resource.category[0].coding[0]] }) : ''}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='encounter' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.encounter.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{handleReference(resource.encounter)}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='authoredOn' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.authoredOn.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource.authoredOn}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='requester' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.requester.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {resource.requester?.reference
                                            ? handleReference(resource.requester)
                                            : resource.requester?.extension ? handleExtension(resource.requester.extension) : ''}
                                    </dd>

                                    <dd>{handleReference(resource.requester)}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='dosageInstruction' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.dosageInstruction.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource.dosageInstruction?.map((dosage, index) => (
                                        <p key={index}>{dosage.text}</p>
                                    ))}
                                    </dd>
                                </Col>
                            </ShowField>

                            <ShowField field='dispenseRequest' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.dispenseRequest.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{`Initial fill: ${resource?.dispenseRequest?.initialFill?.quantity?.value}`}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='text' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource?.text && <Narrative text={resource.text} />}</dd>
                                </Col>
                            </ShowField>
                            <Col sm={12}>
                                <MetaDataFooter
                                    resourceType={resource?.resourceType}
                                    resourceId={resource?.id}
                                    resourceMeta={resource?.meta}
                                    resourceLanguage={resource?.language}
                                    resourceImplicitRules={resource?.implicitRules}
                                />
                            </Col>
                            <Col sm={12}>

                                <ProvenanceDetail resourceName='MedicationRequest' resourceId={medicationDetailId} />

                            </Col>
                        </Row>
                    </>
                )}
            </dl>
        </>
    )
}

export default MedicationDetail
