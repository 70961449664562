import { isCoding } from '../../typeGuards/fhirTypes'
import { Coding } from '../../types/FHIRTypes/Coding'
import { Extension } from '../../types/FHIRTypes/Extension'
import { handleExtension } from '../helpers'

export const handleCodingOrExtension = (value: Coding | Extension | undefined): string | null | undefined => {
    if (!value) return ''
    if (isCoding(value))
            return value.display
    return `Data absent reason: ${handleExtension(value.extension)}`
}