import React, { FC } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { buildCoverageMemberID, calculatedStatus, processCoverageClass, sortCoveragesByStartDate } from '../../../../utils/fhirUtils/coverage'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'
import { buildPeriod, capitalizeFirstLetter, formatDateWithFormat, handleCodeableConcept, handleExtension, handleIdentifier, handleReference } from '../../../../utils/helpers'
import { Coverage as CoverageType } from '../../../../types/FHIRTypes/Coverage'
import ProvenanceDetail from '../Provenance'
import useEnvironment from '../../../../hooks/location/useEnviroment'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'
interface Props {
  coverageData: { entry: { resource: CoverageType }[] };
  patientData: any;
  isFetching: boolean;
}

const config: FieldConfig = {
  // calculated fields
  calculatedStatus: { label: 'Coverage Status', visibility: 'always' },
  calculatedMemberID: { label: 'Member ID', visibility: 'never' },
  // main fields
  identifier: { label: 'Coverage Identifier', visibility: 'always' },
  type: { label: 'Coverage Type', visibility: 'conditional' },
  policyHolder: { label: 'Policy Holder', visibility: 'conditional' },
  subscriber: { label: 'Subscriber', visibility: 'conditional' },
  subscriberId: { label: 'Subscriber ID', visibility: 'always' },
  beneficiary: { label: 'Beneficiary', visibility: 'always' },
  relationship: { label: 'Relationship', visibility: 'always' },
  period: { label: 'Period', visibility: 'always' },
  payor: { label: 'Payor', visibility: 'always' },
  class: { label: 'Coverage Classification', visibility: 'always' },
  network: { label: 'Network', visibility: 'conditional' },
  text: { label: 'Coverage Summary', visibility: 'conditional' },
  dependent: { label: 'Dependent', visibility: 'never' },
  order: { label: 'Order', visibility: 'never' },
  costToBeneficiary: { label: 'Cost To Beneficiary', visibility: 'never' },
  subrogation: { label: 'Subrogation', visibility: 'never' },
  contract: { label: 'Contract', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'always' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  status: { label: 'Resource Status', visibility: 'always' },
}

const Coverage: FC<Props> = ({ coverageData, patientData, isFetching }: Props) => {
  const { data: environmentData } = useEnvironment()
  const patientCoverages = coverageData?.entry?.map((entry) => entry.resource) || []
  const sortedPatientCoverages = sortCoveragesByStartDate(patientCoverages)

  return (
    <>
      <h3>Coverage</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      {
        isFetching ? (
          <Row>
            <Col className='d-flex'>
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Coverage...</span>
            </Col>
          </Row>
        ) : (sortedPatientCoverages.length > 0) ? (
            sortedPatientCoverages.map((resource: CoverageType) => (
            <dl className='dataContainer' key={resource.id}>
            
              <Row>
                <ShowField field='calculatedStatus' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.calculatedStatus.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{calculatedStatus(resource)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleIdentifier(resource.identifier)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='type' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.type.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.type)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='policyHolder' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.policyHolder.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.policyHolder)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='subscriber' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subscriber.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.subscriber)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='subscriberId' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subscriberId.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.subscriberId}</dd>
                  </Col>
                </ShowField>
                <ShowField field='beneficiary' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.beneficiary.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.beneficiary)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='calculatedMemberID' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.calculatedMemberID.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{buildCoverageMemberID(resource, environmentData?.isSingleTenantServer)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='relationship' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.relationship.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.relationship)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='period' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.period.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{buildPeriod(resource.period)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='payor' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.payor.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.payor)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='class' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.class.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                     {resource.class && processCoverageClass(resource.class).map((c, index) => (
                       <div key={`class-${index}`}>
                         <dd>{c.type}: {c.value}</dd>
                       </div>
                     ))}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='network' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.network.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.network}</dd>
                  </Col>
                </ShowField>
                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.text?.div}</dd>
                  </Col>
                </ShowField>
                <ShowField field='dependent' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.dependent.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.dependent}</dd>
                  </Col>
                </ShowField>
                <ShowField field='order' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.order.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.order}</dd>
                  </Col>
                </ShowField>
                <ShowField field='costToBeneficiary' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.costToBeneficiary.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.beneficiary)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='subrogation' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subrogation.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.subrogation ? 'Yes' : 'No'}</dd>
                  </Col>
                </ShowField>
                <ShowField field='contract' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.contract.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.contract)}</dd>
                  </Col>
                </ShowField>
            </Row>
          
            <div className='footer'>
              
            <hr />
              <h6>FHIR Resource Metadata</h6>
              <Row>
                <ShowField field='resourceType' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.resourceType.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.resourceType)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='id' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.id.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.id}</dd>
                  </Col>
                </ShowField>
                <ShowField field='meta' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.meta.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {resource.meta?.lastUpdated
                        ? formatDateWithFormat(
                            resource.meta.lastUpdated,
                            'MM/dd/yyyy HH:mm:ss'
                          )
                        : ''}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='profile' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.profile.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.meta?.profile?.join(', ')}</dd>
                  </Col>
                </ShowField>
                <ShowField field='language' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.language.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.language}</dd>
                  </Col>
                </ShowField>
                <ShowField field='implicitRules' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.implicitRules.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.implicitRules}</dd>
                  </Col>
                </ShowField>
                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.status) || getDataAbsentReason(resource, 'status')}</dd>
                  </Col>
                </ShowField>
              </Row>
              </div>
              <Row>
                <Col sm={12}>
                  <ProvenanceDetail resourceName='Coverage' resourceId={resource.id} />
                </Col>
              </Row>
            
            </dl>
          ))
        ) :
          <Row>
            <Col className='text-center'>
              No Coverage found for this member.
            </Col>
          </Row>
      }
    </>
  )
}

export default Coverage