import React, { FC } from 'react'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { Provenance } from '../../../../types/FHIRTypes/Provenance'
import { formatDateInTimeZone, handleCodeableConcept } from '../../../../utils/helpers'
import TargetResource from '../../DisplayComponents/TargetResource'
import AgentResource from '../../DisplayComponents/AgentResource'
import '../../../../App.scss'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { useEffect } from 'react'

interface Props {
    resourceName: string;
    resourceId: string | undefined;
}

const ProvenanceDetail: FC<Props> = ({ resourceName, resourceId }) => {
    const provenanceSearchParams = { target: `${resourceName}/${resourceId}` }
    const { data: provenanceData, refetch: getProvenance, isFetching: fetchingProvenance, isLoading: loadingProvenance } = useFhirResourceById('', 'Provenance', provenanceSearchParams)
    const provenanceEntries: Provenance[] = provenanceData?.entry?.map((entry: any) => entry.resource) || []
    useEffect(() => {
        if (resourceId) {
            getProvenance()
        }
    }, [resourceId])
    const validProvenanceEntries = provenanceEntries.filter(entry => entry !== undefined)



    if (validProvenanceEntries.length === 0) {
        return null
    }

    return (
        <div className='footer'>
            
            {(fetchingProvenance || loadingProvenance) ? (
                <div>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                    <span style={{ marginLeft: '10px' }}>Loading Provenance Data...</span>
                </div>
            ) : (

                <Row>
                    {validProvenanceEntries.map((provenance, index) => (
                        <Col sm={12} key={index}>
                            
                            <hr />
                            <h6>FHIR Resource Provenance Entry {index + 1}</h6>


                            <Row>
                                <Col sm={3}>
                                    <dt>Target Resource</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {provenance?.target.map((target: any, targetIndex: number) => <TargetResource key={`target-${targetIndex}`} target={target} />)}
                                    </dd>
                                </Col>

                                {provenance?.occurredPeriod && (
                                    <>
                                        <Col sm={3}>
                                            <dt>Occured Period</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{provenance?.occurredPeriod}</dd>
                                        </Col>
                                    </>
                                )}

                                {provenance?.occurredDateTime && (
                                    <>
                                        <Col sm={3}>
                                            <dt>Occured Date</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{formatDateInTimeZone(provenance?.occurredDateTime, 'MM/dd/yyyy', 'UTC')}</dd>
                                        </Col>
                                    </>
                                )}
                                <Col sm={3}>
                                    <dt>Provenance Recorded</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{provenance?.recorded ? provenance?.recorded : ''}</dd>
                                </Col>

                                <Col sm={3}>
                                    <dt>Agent</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {<AgentResource agents={provenance?.agent} />}
                                    </dd>
                                </Col>
                            </Row>


                        </Col>
                    ))}
                </Row>

            )}

        </div>
    )
}



export default ProvenanceDetail